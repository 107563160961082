import React from "react";
import './Digitalmarketing.css';

export default function Digitalmarketing() {
    return (
        <div className="dm">
            <div>
                <div className="title">
                    <img src="/img/marketing.jpg" alt="Digital Marketing" />
                    <div className="ttitle">
                        <h1>Digital Marketing</h1>
                        <p>Digital marketing is the use of online platforms and channels to promote products or services and reach a targeted audience.</p>
                    </div>
                </div>
                <h1 className="why">WHY TRANSKEY CONSULTING?</h1>
                <section className="content ft">
                    <img src="/img/reporting2.jpg" alt="Perceptive Approach" />
                    <div className="text">
                        <h1>1. PRECEPTIVE APPROACH</h1>
                        <p>The only way to get all angles right is to look from beyond. With the full picture we are able to apply our three-dimensional approach in ease to meet our customers agenda.</p>
                    </div>
                </section>
                <section className="content fg">
                    <div className="text">
                        <h1>2. CYCLE</h1>
                        <p>Perceiving a situation from beyond is a lost art as it requires you to not have any agenda. Only then you are able to look at the whole cycle of the business clearly. Upon this clarity we can pretty able to assist our customers to turn to any way they wish to in order to meet their agenda.</p>
                    </div>
                    <img src="/img/reporting.jpg" alt="Cycle" />
                </section>
                <section className="content ft fthree">
                    <div>
                        <img src="/img/customers.jpg" alt="The Launch" />
                    </div>
                    <div className="text">
                        <h1>3. THE LAUNCH</h1>
                        <p>This is part where we execute the proof plan approved by our clients. While the project is on-going full detailed attention along with complete transparency is guaranteed from our team.</p>
                    </div>
                </section>
            </div>

            <div className="services-and-pricing">
                <div className="services">
                    <h2>Our Services</h2>
                    <div className="service-cards">
                        <div className="service-card">
                            <h3>Search Engine Optimization (SEO)</h3>
                            <ul>
                                <li>Keyword Research & Strategy</li>
                                <li>On-Page & Off-Page SEO</li>
                                <li>Local SEO</li>
                            </ul>
                        </div>
                        <div className="service-card">
                            <h3>Pay-Per-Click Advertising (PPC)</h3>
                            <ul>
                                <li>Google Ads</li>
                                <li>Social Media Ads</li>
                                <li>Display Advertising</li>
                            </ul>
                        </div>
                        <div className="service-card">
                            <h3>Social Media Marketing (SMM)</h3>
                            <ul>
                                <li>Social Media Strategy</li>
                                <li>Content Creation & Management</li>
                                <li>Social Media Advertising</li>
                            </ul>
                        </div>
                        <div className="service-card">
                            <h3>Web Design & Development</h3>
                            <ul>
                                <li>Responsive Website Design</li>
                                <li>E-commerce Solutions</li>
                                <li>User Experience (UX) Optimization</li>
                            </ul>
                        </div>
                        <div className="service-card">
                            <h3>Email Marketing</h3>
                            <ul>
                                <li>Email Campaign Management</li>
                                <li>List Building & Segmentation</li>
                                <li>Automation & Personalization</li>
                            </ul>
                        </div>
                        <div className="service-card">
                            <h3>Content Marketing</h3>
                            <ul>
                                <li>Content Strategy</li>
                                <li>Blog Writing</li>
                                <li>Video Marketing</li>
                            </ul>
                        </div>
                        <div className="service-card">
                            <h3>Analytics & Reporting</h3>
                            <ul>
                                <li>Performance Tracking</li>
                                <li>Customized Reports</li>
                                <li>Data-Driven Strategies</li>
                            </ul>
                        </div>
                        <div className="service-card">
                            <h3>Account Management</h3>
                            <ul>
                                <li>Dedicated Account Managers</li>
                                <li>Personalized Strategy and Planning</li>
                                <li>Regular Updates and Communication</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pricing-details">
                    <h2>Packages</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Basic Package</th>
                                <th>Standard Package</th>
                                <th>Premium Package</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>SEO</td>
                                <td>Basic On-Page Optimization</td>
                                <td>Comprehensive On-Page & Off-Page Optimization</td>
                                <td>Advanced On-Page & Off-Page Optimization</td>
                            </tr>
                            <tr>
                                <td>PPC</td>
                                <td>Ad Spend Management</td>
                                <td>Ad Spend Management</td>
                                <td>Ad Spend Management</td>
                            </tr>
                            <tr>
                                <td>Social Media Management</td>
                                <td>2 Platforms</td>
                                <td>3 Platforms</td>
                                <td>All Major Platforms</td>
                            </tr>
                            <tr>
                                <td>Content Marketing</td>
                                <td>2 Blog Posts/Month</td>
                                <td>4 Blog Posts/Month + 2 Videos</td>
                                <td>8 Blog Posts/Month + 4 Videos</td>
                            </tr>
                            <tr>
                                <td>Email Marketing</td>
                                <td>Monthly Newsletter</td>
                                <td>Bi-Weekly Campaigns</td>
                                <td>Weekly Campaigns</td>
                            </tr>
                            <tr>
                                <td>Web Design & Development</td>
                                <td>Single Page Website</td>
                                <td>Multi-page Website (5 pages)</td>
                                <td>Full Site Management</td>
                            </tr>
                            <tr>
                                <td>Analytics & Reporting</td>
                                <td>Monthly Report</td>
                                <td>Bi-Weekly Reports</td>
                                <td>Weekly Reports with In-Depth Analysis</td>
                            </tr>
                            <tr>
                                <td>Account Management</td>
                                <td>Dedicated Account Manager</td>
                                <td>Dedicated Account Manager</td>
                                <td>Senior Account Manager</td>
                            </tr>
                            <tr>
                                <td>Additional Services</td>
                                <td>-</td>
                                <td>-</td>
                                <td>Customized Strategies, Priority Support</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Next Steps: Confirm your preferred options and provide payment details. Contact us for customized packages and one-time services.</p>
                </div>
            </div>

            <div className="hosting-details">
               

                <div className="payment-details">
                    <h2>Payment Details</h2>
                    <div className="payment-card">
                        <p><strong>Name:</strong> Transkey consulting global services</p>
                        <p><strong>Account no:</strong> 1698115000002264</p>
                        <p><strong>Bank name:</strong> Karur Vysya Bank</p>
                        <p><strong>IFSC code:</strong> KVBL0001698</p>
                        <p><strong>Swift code:</strong> KVBLINBBIND</p>
                    </div>

                      </div>
            </div>
        </div>
    );
}
